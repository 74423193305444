import { useCallback } from 'react';

import { useInitialServiceConfig } from 'config/useInitialServiceConfig';
import { trackActionEvent, trackViewEvent } from 'store/tracking';
import { useAppDispatch } from './useAppDispatch';

type TrackActionObj = (obj: { category: string; action: string; value?: string }) => void;
type TrackActionParams = (category: string, action: string, value?: string) => void;
type TrackAction = TrackActionParams | TrackActionObj;

function isObjVariant(
  args: Parameters<TrackActionObj> | Parameters<TrackActionParams>,
): args is Parameters<TrackActionObj> {
  return typeof args[0] === 'object';
}

export function useTracking() {
  const dispatch = useAppDispatch();
  const { clientType } = useInitialServiceConfig();

  const trackAction = useCallback(
    (...args: Parameters<TrackAction>): ReturnType<TrackAction> => {
      if (isObjVariant(args)) {
        dispatch(
          trackActionEvent({
            dataSource: clientType,
            category: args[0].category,
            action: args[0].action,
            value: args[0].value,
          }),
        );
      } else {
        dispatch(
          trackActionEvent({
            dataSource: clientType,
            category: args[0],
            action: args[1],
            value: args[2],
          }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientType],
  );

  const trackView = useCallback(
    (screenname: string, userInteraction = true) => {
      dispatch(
        trackViewEvent({
          dataSource: clientType,
          screenname,
          userInteraction,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientType],
  );

  return { trackAction, trackView };
}
