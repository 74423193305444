import { configureStore } from '@reduxjs/toolkit';

import reducer from './rootReducer';

export function setupStore(preloadedState?: Partial<RootState>) {
  const store = configureStore({
    preloadedState,
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  });

  return store;
}

export default setupStore();
