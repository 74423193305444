import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { api } from 'api';
import type { AjaxResponse } from 'models/types/AjaxResponse';
import { mapToAjaxResponseObject } from 'utils/utils';
import type { ResendVerificationEmailDataType, VerifyAccountDataType } from '../types/types';

export const verifyAccount = createAsyncThunk<AjaxResponse, VerifyAccountDataType>(
  'verifyaccount/verifyaccount',
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post('/account/verifyaccount', data);

      return mapToAjaxResponseObject(response.data);
    } catch (error) {
      return rejectWithValue(axios.isAxiosError(error) && error.message);
    }
  },
);

export const resendVerificationEmail = createAsyncThunk<AjaxResponse, ResendVerificationEmailDataType>(
  'verifyaccount/resetverificationemail',
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post('/account/resendvalidationemail', data);

      return mapToAjaxResponseObject(response.data);
    } catch (error) {
      return rejectWithValue(axios.isAxiosError(error) && error.message);
    }
  },
);
