import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { mapToAjaxResponseObject } from 'utils/utils';

export const getGrantablePermissions = createAsyncThunk(
  'oauth/getgrantablepermissions',
  async (
    data: {
      clientId: string;
      redirectUri: string;
      responseType: string;
      scope?: string;
      state?: string;
      codeChallenge?: string;
      codeChallengeMethod?: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.post('/oauth2/getgrantablepermissions', data);

      return mapToAjaxResponseObject(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const grantAccess = createAsyncThunk(
  'oauth/grantaccess',
  async (
    data: {
      clientId: string;
      redirectUri: string;
      responseType: string;
      scope?: string;
      state?: string;
      codeChallenge?: string;
      codeChallengeMethod?: string;
      isTrialLicenseNeeded?: boolean;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.post('/oauth2/grantaccess', data);

      return mapToAjaxResponseObject(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
