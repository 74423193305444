import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { generateStringIdForErrorMessage } from 'utils/ErrorPayloadHandling';
import { Translatable } from 'utils/translation';
import { ResendVerificationEmailStatus, VerifyAccountStatus } from '../types/enums';
import type { IVerifyAccountState } from '../types/interfaces';
import { resendVerificationEmail, verifyAccount } from './verifyAccountAsyncActions';

const initialState: IVerifyAccountState = {
  status: VerifyAccountStatus.Initial,
  resendStatus: ResendVerificationEmailStatus.Initial,
  error: {
    isError: false,
  },
};

const verifyAccountSlice = createSlice({
  name: 'verifyaccount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifyAccount.pending, (state, _action) => {
        state.status = VerifyAccountStatus.Verifying;
      })
      .addCase(verifyAccount.fulfilled, (state, { payload }) => {
        if (payload.isSuccess) {
          state.status = VerifyAccountStatus.Successed;
        } else {
          state.status = VerifyAccountStatus.Invalid;
          state.error = {
            isError: true,
            errorCode: payload.errorCode,
            messageCreator: () => '',
          };
        }
      })
      .addCase(resendVerificationEmail.pending, (state, _action) => {
        state.resendStatus = ResendVerificationEmailStatus.InProgress;
      })
      .addCase(resendVerificationEmail.fulfilled, (state, { payload }) => {
        if (payload.isSuccess) {
          state.resendStatus = ResendVerificationEmailStatus.Successed;
        } else {
          state.resendStatus = ResendVerificationEmailStatus.Failed;
          state.error = {
            isError: true,
            errorCode: payload.errorCode,
            messageCreator: generateStringIdForErrorMessage({
              responseCodeType: payload.responseCodeType,
              responseCode: payload.responseCode,
            }),
          };
        }
      })
      .addMatcher(isAnyOf(verifyAccount.rejected, resendVerificationEmail.rejected), (state, _action) => {
        state.error = {
          isError: true,
          messageCreator: Translatable.fromStringId('error:unexpectedError'),
        };
      });
  },
});

export const verifyAccountActions = verifyAccountSlice.actions;
export const verifyAccountReducer = verifyAccountSlice.reducer;
