import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Icon, PrimaryButton, Stack, TertiaryButton, TextPrimary, TextSecondary } from '@teamviewer/ui-library';
import type { OAuthGrantablePermissions } from 'models';

import { api } from 'api';
import { Modal, ModalMain } from 'components';
import { useAppDispatch, useAppSelector, useConfirmationToast, useErrorMessage } from 'hooks';
import type { OAuthGrantAccessPermissionsType } from 'models/enums/OAuthGrantAccessPermissions';
import { OAuthGrantAccessPermissions } from 'models/enums/OAuthGrantAccessPermissions';
import Loading from 'pages/Loading';
import { getGrantablePermissions, grantAccess, oauthActions } from 'store';

import { useStyles } from './OAuthGrantAccess.styles';

interface IPermissionsGroup {
  title: string;
  permissionsList: string[];
}

const OAuthGrantAccess = () => {
  const { t } = useTranslation(['grantaccess', 'oauthpermissions', 'error']);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const {
    isLoading,
    inProgress,
    autoGrantAccess,
    getGrantablePermissionsResponse: grantablePermissionsResponse,
  } = useAppSelector((state) => state.oauth);
  const { errorMessage, errorCode } = useErrorMessage((state) => state.oauth.error);

  const {
    accordionContainerStyles,
    accordionHeaderStyles,
    accordionItemStyles,
    accordionTextStyles,
    buttonsContainerStyles,
    contentStyles,
    logoImgStyle,
    permissionInfoStyles,
    permissionsListStyles,
    permissionStyles,
    titleStyles,
  } = useStyles();

  useConfirmationToast({
    errorProps: {
      icon: 'WarningIcon',
      message: errorMessage,
      errorCode,
    },
    resetAction: () => {
      dispatch(
        oauthActions.setError({
          isError: false,
        }),
      );
    },
    showError: errorMessage !== '',
  });

  useEffect(() => {
    dispatch(
      getGrantablePermissions({
        clientId: searchParams.get('client_id') ?? '',
        redirectUri: searchParams.get('redirect_uri') ?? '',
        responseType: searchParams.get('response_type') ?? '',
        state: searchParams.get('state') ?? undefined,
        scope: searchParams.get('scope') ?? undefined,
        codeChallenge: searchParams.get('code_challenge') ?? undefined,
        codeChallengeMethod: searchParams.get('code_challenge_method') ?? undefined,
      }),
    );
  }, [dispatch, searchParams]);

  const permissionsGroups = useMemo(() => {
    if (!grantablePermissionsResponse?.GrantablePermissions) {
      return;
    }

    const grantablePermissions: OAuthGrantablePermissions = grantablePermissionsResponse.GrantablePermissions;
    const permissions: IPermissionsGroup[] = [];

    Object.keys(OAuthGrantAccessPermissions).forEach((permissionKey: string) => {
      const permissionTitle = permissionKey as OAuthGrantAccessPermissionsType;
      const permission = grantablePermissions[`${permissionTitle}Translation`];

      if (permission) {
        const permissionsList: string[] = grantablePermissions[`${permissionTitle}Translation`]
          .split(',')
          .map((value) => value.trim());

        permissions.push({
          title: permissionTitle,
          permissionsList,
        });
      }
    });

    return permissions;
  }, [grantablePermissionsResponse]);

  const onAllow = useCallback(async () => {
    const response = await dispatch(
      grantAccess({
        clientId: searchParams.get('client_id') ?? '',
        redirectUri: searchParams.get('redirect_uri') ?? '',
        responseType: searchParams.get('response_type') ?? '',
        state: searchParams.get('state') ?? undefined,
        scope: searchParams.get('scope') ?? undefined,
        codeChallenge: searchParams.get('code_challenge') ?? undefined,
        codeChallengeMethod: searchParams.get('code_challenge_method') ?? undefined,
        isTrialLicenseNeeded: grantablePermissionsResponse?.IsTrialLicenseNeeded,
      }),
    ).unwrap();

    if (response.data) {
      const url = new URL(response.data, window.location.origin);
      window.location.replace(url);
    }
  }, [dispatch, grantablePermissionsResponse?.IsTrialLicenseNeeded, searchParams]);

  const onDeny = () => {
    dispatch(oauthActions.setInProgress(true));

    // Trigger a logout before we leave
    api.post('/logout');

    if (grantablePermissionsResponse?.DenyAccessUrl) {
      window.location.replace(grantablePermissionsResponse.DenyAccessUrl);
    } else {
      const redirectUri = searchParams.get('redirect_uri');
      window.location.replace(`${redirectUri}/?code=DENIED_BY_USER`);
    }
  };

  useEffect(() => {
    if (autoGrantAccess) {
      onAllow();
    }
  }, [autoGrantAccess, onAllow]);

  const togglePermission = (index: number) => {
    if (index === selectedIndex) {
      setSelectedIndex(null);
    } else {
      setSelectedIndex(index);
    }
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Modal>
      <ModalMain>
        <Stack className={contentStyles} verticalAlign="space-between" data-testid="grant-access-container">
          <Stack.Item>
            <Stack horizontalAlign="center">
              <img src="/assets/tv-icon.svg" alt="TeamViewer Logo" className={logoImgStyle} data-testid="tv-logo" />
              <TextPrimary className={titleStyles} data-testid="grant-access-title">
                {t('title')}
              </TextPrimary>
              <TextSecondary data-testid="grant-access-description">
                {t('description', { app: grantablePermissionsResponse?.AppName })}
              </TextSecondary>
            </Stack>
            {grantablePermissionsResponse?.ShowPermissionInfoText && (
              <Stack horizontalAlign="start" className={permissionInfoStyles}>
                <TextPrimary variant="small" data-testid="permission-info-text">
                  {grantablePermissionsResponse.PermissionInfoText}
                </TextPrimary>
              </Stack>
            )}
            <Stack className={accordionContainerStyles} horizontalAlign="start">
              {permissionsGroups?.map((permissionGroup, i) => (
                <Stack.Item key={permissionGroup.title} className={accordionItemStyles}>
                  <Stack
                    className={accordionHeaderStyles}
                    horizontal
                    horizontalAlign="space-between"
                    verticalAlign="center"
                    onClick={() => togglePermission(i)}
                  >
                    <TextPrimary className={accordionTextStyles}>
                      {t(`oauthpermissions:${permissionGroup.title}`)}
                    </TextPrimary>
                    <Icon iconName={i === selectedIndex ? 'ChevronUpSmall' : 'ChevronDownSmall'} />
                  </Stack>
                  {i === selectedIndex && (
                    <ul className={permissionsListStyles}>
                      {permissionGroup.permissionsList.map((permission: string) => (
                        <li key={permission} className={permissionStyles}>
                          {permission}
                        </li>
                      ))}
                    </ul>
                  )}
                </Stack.Item>
              ))}
            </Stack>
          </Stack.Item>
          <Stack className={buttonsContainerStyles} horizontal horizontalAlign="end" verticalAlign="end">
            <TertiaryButton onClick={onDeny} disabled={inProgress}>
              {t('deny')}
            </TertiaryButton>
            <PrimaryButton onClick={onAllow} isLoading={inProgress}>
              {t('allow')}
            </PrimaryButton>
          </Stack>
        </Stack>
      </ModalMain>
    </Modal>
  );
};

export default OAuthGrantAccess;
