import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { BackendError, OAuthGrantablePermissionsResponse } from 'models';

import { generateStringIdForErrorMessage } from 'utils/ErrorPayloadHandling';
import { Translatable } from 'utils/translation';
import { getGrantablePermissions, grantAccess } from './oauthAsyncActions';

interface OAuthState {
  isLoading: boolean;
  inProgress: boolean;
  autoGrantAccess: boolean;
  getGrantablePermissionsResponse?: OAuthGrantablePermissionsResponse;
  redirectUri?: string;
  error: BackendError;
}

const initialState: OAuthState = {
  isLoading: true,
  inProgress: false,
  autoGrantAccess: false,
  error: {
    isError: false,
  },
};

const oauthSlice = createSlice({
  name: 'oauth',
  initialState,
  reducers: {
    setInProgress(state, { payload }: PayloadAction<OAuthState['inProgress']>) {
      state.inProgress = payload;
    },
    setError(state, { payload }: PayloadAction<OAuthState['error']>) {
      state.error = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGrantablePermissions.pending, (state, _action) => {
      state.isLoading = true;
    });
    builder.addCase(getGrantablePermissions.fulfilled, (state, { payload }) => {
      if (payload.isSuccess) {
        if (payload.data?.autoGrantAccess) {
          state.autoGrantAccess = true;
        } else {
          state.getGrantablePermissionsResponse = payload.data as OAuthGrantablePermissionsResponse;
        }
      }

      if (!payload.isSuccess) {
        if (payload.data?.isTrialLicenseExpired) {
          state.error = {
            isError: true,
            messageCreator: Translatable.fromStringId('error:expiredTrialForOauth'),
          };
        } else {
          state.error = {
            isError: true,
            responseCode: payload.responseCode,
            errorCode: payload.errorCode,
            responseCodeType: payload.responseCodeType,
            messageCreator: generateStringIdForErrorMessage({
              responseCodeType: payload.responseCodeType,
              responseCode: payload.responseCode,
            }),
          };
        }
      }

      state.isLoading = payload.data?.autoGrantAccess || !payload.isSuccess;
    });
    builder.addCase(getGrantablePermissions.rejected, (state, _action) => {
      state.error = {
        isError: true,
        messageCreator: Translatable.fromStringId('error:unexpectedError'),
      };
    });
    builder.addCase(grantAccess.pending, (state, _action) => {
      state.inProgress = true;
    });
    builder.addCase(grantAccess.fulfilled, (state, { payload }) => {
      state.redirectUri = payload.data;
    });
  },
});

export const oauthActions = oauthSlice.actions;
export const oauthReducer = oauthSlice.reducer;
