import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import type { IAuthState } from 'models';

import { AuthProtocol, LoginMethod, LoginStatus, SocialAccountLoginIssuer } from 'models';
import {
  getAccountLoginMethodAction,
  getSsoVerificationToken,
  loginAction,
  loginTokenAction,
  ssoReturnUrlAction,
  tfaAction,
} from 'store/auth/authAsyncActions';
import { generateStringIdForErrorMessage } from 'utils/ErrorPayloadHandling';
import { Translatable } from 'utils/translation';

const initialState: IAuthState = {
  loginMethod: LoginMethod.NotSet,
  authProtocol: AuthProtocol.TvAuth,
  loginStatus: LoginStatus.ReadyForLogin,
  isLoginInProgress: false,
  message: '',
  keepMeSignedIn: localStorage.getItem('keepMeSignedIn') === '1' ? 1 : 0,
  autocompletedPassword: '',
  accountInfo: {
    username: '',
    password: '',
    accountId: '',
    sessionId: '',
    sessionSecret: '',
    encryptedAccountSecret: '',
    accountAuthState: '',
  },
  ssoInfo: {
    ssoVerificationToken: '',
    ssoServiceUrl: '',
    ssoLogoutUrl: '',
    ssoCustomerId: '',
    isNewSsoLoginTokenRequired: false,
  },
  socialLoginInfo: {
    socialAccountIssuer: 'None',
  },
  loginTokenInfo: {
    tokenId: '',
    token: '',
  },
  targetInstanceNumber: 0,
  error: {
    isError: false,
  },
  enforcedTfaInfo: {
    enforcedTfaToken: '',
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoginMethod(state, action: PayloadAction<LoginMethod>) {
      state.loginMethod = action.payload;
    },
    setIsLoginInProgress(state, action: PayloadAction<boolean>) {
      state.isLoginInProgress = action.payload;
    },
    setUsername(state, { payload }: PayloadAction<string>) {
      state.accountInfo.username = payload;
    },
    setAutocompletedPassword(state, { payload }: PayloadAction<string>) {
      state.autocompletedPassword = payload;
    },
    setKeepMeSignedIn(state, { payload }: PayloadAction<0 | 1>) {
      state.keepMeSignedIn = payload;
    },
    setAccountInfo(
      state,
      {
        payload,
      }: PayloadAction<{
        username: string;
        password: string;
        accountId: string;
        sessionId: string;
        sessionSecret: string;
        encryptedAccountSecret: string;
        accountAuthState: string;
      }>,
    ) {
      state.accountInfo = payload;
    },
    setLoginStatus(state, { payload }: PayloadAction<LoginStatus>) {
      state.loginStatus = payload;
    },
    setError(state, { payload }: PayloadAction<IAuthState['error']>) {
      state.error = payload;
    },
    setAuthProtocol(state, { payload }: PayloadAction<AuthProtocol>) {
      state.authProtocol = payload;
    },
    setIsNewSsoLoginRequired(state, { payload }: PayloadAction<boolean>) {
      state.ssoInfo.isNewSsoLoginTokenRequired = payload;
    },
    setSsoVerificationToken(state, { payload }: PayloadAction<string>) {
      state.ssoInfo.ssoVerificationToken = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountLoginMethodAction.fulfilled, (state, { payload }) => {
        state.loginMethod = payload.AccountLoginMethod;

        switch (payload.AccountLoginMethod) {
          case LoginMethod.Sso: {
            state.loginStatus = LoginStatus.SsoLoginRequired;
            state.ssoInfo.ssoServiceUrl = payload.SsoServiceUrl ?? '';
            state.isLoginInProgress = true;
            break;
          }
          case LoginMethod.SocialLogin: {
            state.loginStatus = LoginStatus.SocialAccountLoginRequired;
            state.socialLoginInfo.socialAccountIssuer = SocialAccountLoginIssuer.fromNumber(payload.SocialLoginIssuer);
            state.ssoInfo.ssoVerificationToken = payload.SsoVerificationToken;
            state.isLoginInProgress = true;
            break;
          }
          case LoginMethod.UsernameAndPassword: {
            state.ssoInfo.ssoVerificationToken = payload.SsoVerificationToken;
            state.isLoginInProgress = false;
            break;
          }
          default: {
            state.isLoginInProgress = false;
            break;
          }
        }
      })
      .addCase(loginAction.fulfilled, (state, { payload }) => {
        state.loginStatus = payload.loginStatus;

        if (payload.loginStatus === LoginStatus.TrustDevicePending) {
          state.message = payload.message ? payload.message : 'Device verification pending.';
        }

        if (
          payload.loginStatus === LoginStatus.EnforcedTfa &&
          payload?.enforcedTfaInfo?.enforcedTfaToken !== undefined
        ) {
          state.enforcedTfaInfo.enforcedTfaToken = payload.enforcedTfaInfo.enforcedTfaToken;
        }

        if (payload.TargetInstanceNumber) {
          state.targetInstanceNumber = payload.TargetInstanceNumber;
        }

        if (payload.loginStatus !== LoginStatus.Authenticated) {
          state.isLoginInProgress = false;
        }
      })
      .addCase(tfaAction.fulfilled, (state, action) => {
        const payload = action.payload;
        state.loginStatus = payload.loginStatus;
        state.isLoginInProgress = false;
      })
      .addCase(ssoReturnUrlAction.fulfilled, (state, action) => {
        const payload = action.payload;

        if (payload.customerId) {
          state.ssoInfo.ssoCustomerId = payload.customerId;
        }

        if (payload.loginStatus) {
          state.loginStatus = payload.loginStatus;
          if (payload.loginStatus === LoginStatus.SsoOneTimePasswordLoginRequired) {
            state.isLoginInProgress = false;
          }
        }

        if (payload.ssoVerificationToken) {
          state.ssoInfo.ssoVerificationToken = payload.ssoVerificationToken;
        }

        if (payload.ssoLogoutUrl) {
          state.ssoInfo.ssoLogoutUrl = payload.ssoLogoutUrl;
        }
      })
      .addCase(loginTokenAction.fulfilled, (state, action) => {
        const payload: any = action.payload;

        if (payload.loginStatus === LoginStatus.ReadyToRedirect) {
          state.loginStatus = payload.loginStatus;
        }

        if (payload.tokenId && payload.token) {
          state.loginStatus = LoginStatus.ReadyToRedirect;
          state.loginTokenInfo = {
            tokenId: payload.tokenId,
            token: payload.token,
          };
        }

        state.isLoginInProgress = false;
      })
      .addCase(getSsoVerificationToken.fulfilled, (state, action) => {
        state.ssoInfo.ssoVerificationToken = action.payload;
        state.isLoginInProgress = false;
      })
      .addCase(getAccountLoginMethodAction.pending, (state, _action) => {
        state.loginMethod = LoginMethod.NotSet;
        state.ssoInfo = {
          ssoVerificationToken: '',
          ssoServiceUrl: '',
          ssoLogoutUrl: '',
          ssoCustomerId: '',
          isNewSsoLoginTokenRequired: false,
        };
        state.socialLoginInfo = {
          socialAccountIssuer: 'None',
        };
      })
      .addCase(getSsoVerificationToken.pending, (state, _action) => {
        state.isLoginInProgress = true;
      })
      .addMatcher(
        isAnyOf(
          loginAction.pending,
          ssoReturnUrlAction.pending,
          tfaAction.pending,
          getAccountLoginMethodAction.pending,
        ),
        (state, _action) => {
          state.loginStatus = LoginStatus.InProgress;
          state.isLoginInProgress = true;
          state.message = '';
          state.error = {
            isError: false,
          };
        },
      )
      .addMatcher(
        isAnyOf(
          loginAction.rejected,
          tfaAction.rejected,
          ssoReturnUrlAction.rejected,
          loginTokenAction.rejected,
          getAccountLoginMethodAction.rejected,
          getSsoVerificationToken.rejected,
        ),
        (state, action) => {
          const payload: any = action.payload;

          if (payload) {
            state.loginStatus = payload.loginStatus;

            state.error = {
              isError: true,
              messageCreator: Translatable.tryLiterallyOrElse(payload?.message, () => ''),
              responseCodeType: payload.responseCodeType ?? 0,
              responseCode: payload.responseCode ?? 0,
              errorCode: payload.errorCode ?? '',
            };
          } else {
            state.loginStatus = LoginStatus.Failed;
            state.error = {
              isError: true,
              messageCreator: Translatable.tryLiterallyOrElse(
                action.error.message,
                Translatable.literally('Unknown error. Please try again later.'),
              ),
            };
          }

          state.isLoginInProgress = false;

          // In case web backend provides an error message then it will show directly the end user
          // This error messages from backend shall be already translated
          state.error.messageCreator = Translatable.tryLiterallyOrElse(
            payload?.message,
            generateStringIdForErrorMessage({
              responseCodeType: state.error.responseCodeType,
              responseCode: state.error.responseCode,
              embeddedStringId: state.error.embeddedStringId ?? '',
            }),
          );
        },
      );
  },
});
export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
