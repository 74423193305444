import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  CheckboxFormInput,
  PasswordFormInput,
  spacing,
  Stack,
  TextButton,
  TextPrimary,
  TextWithTooltip,
  themeSpacings,
} from '@teamviewer/ui-library';

import {
  Banner,
  IconLink,
  InfoContainer,
  LinkWithRedirect,
  Modal,
  ModalBanner,
  ModalMain,
  SubmitButton,
} from 'components';
import { useConfigManager } from 'config/useConfigManager';
import { useInitialServiceConfig } from 'config/useInitialServiceConfig';
import { useAppDispatch, useAppSelector, useFormWithCaptcha, useTracking } from 'hooks';
import { useStayTunedDialog } from 'hooks/use-stay-tuned-dialog';
import { AuthProtocol, ClientType, LoginStatus } from 'models';
import { useAnalytics } from 'modules/Analytics';
import { authActions, loginAction } from 'store';
import { useLinkStyles } from 'utils/commonStyles';
import { getPasswordValidationRules } from 'utils/validationRules';

import { useStyles } from './PasswordForm.styles';

export const PasswordForm = () => {
  const configManager = useConfigManager();
  const featureFlags = configManager.get('featureFlags');
  const { trackAction } = useTracking();
  const { render: renderStayTunedDialog, toggle: toggleStayTunedDialog } = useStayTunedDialog();
  const { t } = useTranslation(['login', 'validation', 'common', 'banner']);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const redirectUri = searchParams.get('redirect_uri') ?? configManager.get('defaultRedirectUri');
  const { authProtocol, isLoginInProgress, accountInfo, error, autocompletedPassword, ssoInfo, keepMeSignedIn } =
    useAppSelector((state) => state.auth);
  const loginStatus = useAppSelector((state) => state.auth.loginStatus);
  const email = accountInfo.username;
  const [displayedEmail] = useState(email);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckmarkVisible, setIsCheckmarkVisible] = useState(false);
  const { linkStyles } = useLinkStyles();
  const isNativeClient = useInitialServiceConfig().clientType === ClientType.NativeClient;

  const { formSectionStyles, optionalTextStyles, titleStyles, tooltipHost, textWrapStyles } = useStyles();

  // Track GTM Page View
  const { analyticsAction, virtualPageView } = useAnalytics();
  useEffect(() => {
    if (loginStatus === LoginStatus.ReadyToRedirect) {
      analyticsAction({ action: 'signIn', method: 'email' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);

  const {
    control,
    watch,
    trigger,
    formState: { isValid },
    recaptchaLoaded,
    captchaComponent,
    executeRecaptcha,
    resetField,
  } = useFormWithCaptcha<{ email: string; password: string; keepMeSignedIn: boolean }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      email,
      password: autocompletedPassword ?? '',
      keepMeSignedIn: isNativeClient && !localStorage.getItem('keepMeSignedIn') ? true : !!keepMeSignedIn,
    },
    async onSubmit({ captchaResponse, email, password }) {
      trackAction('sign-in', 'sign-in');
      dispatch(
        loginAction({
          username: email,
          password,
          redirectUri,
          ssoVerificationToken: ssoInfo.ssoVerificationToken,
          'invisiblecaptcha-response': captchaResponse,
        }),
      );
    },
  });

  const password = watch('password');
  const keepMeSignedInField = watch('keepMeSignedIn');

  useEffect(() => {
    if (password) {
      trigger('password');
    }
  }, [password, trigger]);

  useEffect(() => {
    dispatch(authActions.setKeepMeSignedIn(keepMeSignedInField ? 1 : 0));
  }, [keepMeSignedInField, dispatch, isNativeClient]);

  useEffect(() => {
    if (error.isError) {
      resetField('password');
    }
  }, [error, resetField]);

  useEffect(() => {
    setIsLoading(
      isLoginInProgress ||
        !recaptchaLoaded ||
        (loginStatus === LoginStatus.ReadyToRedirect && authProtocol === AuthProtocol.OAuth),
    );
  }, [isLoginInProgress, recaptchaLoaded, loginStatus, authProtocol]);

  useEffect(() => {
    setIsCheckmarkVisible(loginStatus === LoginStatus.ReadyToRedirect && authProtocol !== AuthProtocol.OAuth);
  }, [loginStatus, authProtocol]);

  useEffect(() => {
    virtualPageView({ action: 'sign_in', method: 'enter_password' });
    document.getElementById('password-input')?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal>
      <ModalMain>
        {renderStayTunedDialog()}
        <Stack>
          <IconLink
            iconName="Back"
            as="LinkWithRedirect"
            link="/"
            disabled={isLoading}
            onClick={() => {
              dispatch(authActions.setUsername(''));
              searchParams.delete('email');
            }}
            tabIndex={1}
            data-testid="signin-go-back-link"
          >
            <TextPrimary as="h1" block data-testid="signin-password-title" className={titleStyles}>
              {t('enterpassword')}
            </TextPrimary>
          </IconLink>
          <Stack>
            <InfoContainer
              data-testid="signin-password-email-info-container"
              icon="./assets/default_account_profile_picture.svg"
              iconAccessibility={t('common:profilePictureAccessibility')}
              text={<TextWithTooltip hostClassName={tooltipHost} text={displayedEmail} />}
              textWrapStyles={textWrapStyles}
            />
          </Stack>
        </Stack>
        {captchaComponent}
        <Stack
          as="form"
          data-testid="signin-password-form"
          className={formSectionStyles}
          tokens={{ childrenGap: themeSpacings.l }}
          onSubmit={executeRecaptcha}
        >
          <PasswordFormInput
            id="password-input"
            name="password"
            control={control}
            rules={getPasswordValidationRules(t)}
            autoFocus
            inputMode="text"
            autoComplete="current-password"
            label={t('common:password')}
            ariaLabel={t('common:password')}
            tabIndex={2}
            data-testid="signin-password-input"
          />
          <Stack.Item>
            <CheckboxFormInput
              name="keepMeSignedIn"
              control={control}
              label={t('keepMeSignedIn')}
              ariaLabel={t('keepMeSignedIn')}
              onChange={() => trackAction('sign-in', 'keep-me-signed-in', (!keepMeSignedInField).toString())}
              data-testid="signin-password-keep-login-checkbox"
              tabIndex={3}
              disabled={isLoading}
            />
          </Stack.Item>
          <Stack.Item>
            <Stack tokens={{ childrenGap: spacing(8) }}>
              <SubmitButton
                label={t('signIn')}
                checkedLabel={t('signedIn')}
                isValid={isValid}
                isCheckmarkVisible={isCheckmarkVisible}
                isLoading={isLoading}
                tabIndex={4}
                data-testid="signin-password-submit-btn"
              />
              <Stack.Item className={optionalTextStyles}>
                {featureFlags.forgotPassword ? (
                  <LinkWithRedirect
                    data-testid="signin-forgot-password-link"
                    className={linkStyles}
                    href={`/forgot-password?email=${email}`}
                    tabIndex={5}
                    disabled={isLoading}
                    onClick={() => {
                      trackAction('sign-in', 'password-recovery-initiated');
                    }}
                  >
                    {t('forgotPassword')}
                  </LinkWithRedirect>
                ) : (
                  <TextButton
                    data-testid="signin-forgot-password-link"
                    className={linkStyles}
                    disabled={isLoading}
                    onClick={() => {
                      toggleStayTunedDialog();
                    }}
                    tabIndex={5}
                  >
                    {t('forgotPassword')}
                  </TextButton>
                )}
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </ModalMain>
      <ModalBanner>
        <Banner.JustAStepAway />
      </ModalBanner>
    </Modal>
  );
};
