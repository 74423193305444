export enum VerifyAccountStatus {
  Initial = 'Initial',
  Verifying = 'Verifying',
  Invalid = 'Invalid',
  Successed = 'Successed',
}

export enum ResendVerificationEmailStatus {
  Initial = 'Initial',
  InProgress = 'InProgress',
  Successed = 'Successed',
  Failed = 'Failed',
}
